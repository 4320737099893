import React, { Suspense, lazy } from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route
  } from 'react-router-dom'
import Overview from './responsive/overview';

const WindowManager = lazy(() => import('./windows/windowmanager'))
const Shell = lazy(() => import('./shell/shell'))

function App() {
  return (
    <Router>
        <Suspense fallback={<div>Loading...</div>}>
            <Routes className="Container">
                <Route path="*" element={<Overview />}/>
                <Route path="start" element={<WindowManager />}/>
                <Route path="shell" element={<Shell />}/>
                <Route path="overview/*" element={<Overview />}/>
            </Routes>
        </Suspense>
    </Router>
  );
}

export default App;
