import React, { Suspense, lazy } from 'react';
import peopleicon from './icons/peopleicon.png'; 
import technologyicon from './icons/technologyicon.png';
import productsicon from './icons/productsicon.png';
import linksicon from './icons/linksicon.png';
import infoicon from './icons/infoicon.png';
import lawicon from './icons/lawicon.png';
import urkundeicon from './icons/urkundeicon.png';
import newsicon from './icons/newsicon.png';

const About = lazy(() => import('./sites/about'))
const Technologies = lazy(() => import('./sites/technologies'))
const Products = lazy(() => import('./sites/products'))
const Links = lazy(() => import('./sites/links'))
const Impressum = lazy(() => import('./sites/impressum'))
const Datenschutz = lazy(() => import('./sites/datenschutz'))
const Kollektivvertrag = lazy(() => import('./sites/kollektivvertrag'))
const Nextcloud = lazy(() => import('./sites/nextcloud'))
const BBB = lazy(() => import('./sites/bbb'))
const ERP = lazy(() => import('./sites/erp'))
const Moodle = lazy(() => import('./sites/moodle'))
const WooCommerce = lazy(() => import('./sites/woocommerce'))
const Crew = lazy(() => import('./sites/crew'))
const Aktuelles = lazy(() => import('./sites/aktuelles'))

export const apps = [
{
    id: -1,
    title: ">make Utopia",
    content: (<div>
            <img src="/images/logo.png" alt=">make Utopia Logo" style={{width: '100%'}} />
            <div className="pagePart">
            <h2>make Utopia GUI Version 2.4</h2>
            <p>Nachdem die erste Seite von make Utopia noch einem Terminal nachempfunden war,
            dachten wir uns, dass es Zeit ist sich dem Zeitgeist zu öffnen und eine
            Seite mit graphischer Benutzer-Oberfläche zu erstellen.</p>
            <p>Außerdem zeigen wir damit gleich, was für Nerds wir sind und
            dass wir auch ausgefallene Seiten Konzepte umsetzen können.</p>
            <p>Wir hoffen, die Seite gefällt euch so gut wie uns und ihr fühlt euch
            ein wenig an die guten Seiten der 80er/90er Jahre erinnert.</p>
            <p style={{textAlign: 'center'}}>Copyright (C) since 2019 by make Utopia GmbH - IT Kollektiv Hamburg.</p>
            </div>
            </div>
    )
},
{
    id: 14,
    icon: newsicon,
    autostart: true,
    title: "Aktuelles",
    link: "aktuelles",
    description: "Neuigkeiten",
    content: <Suspense fallback="<div>Loading...</div>"><Aktuelles /></Suspense>
},
{
    id: 0,
    icon: productsicon,
    autostart: false,
    title: "Produkte & Angebote",
    link: "products",
    description: "Produkte und Angebote",
    content: <Suspense fallback="<div>Loading...</div>"><Products /></Suspense>
},
{
    id: 1,
    icon: technologyicon,
    title: "Technologien",
    link: "technologies",
    description: "Verwendete Technologien",
    content: <Suspense fallback="<div>Loading...</div>"><Technologies /></Suspense>
},
{
    id: 2,
    autostart: false,
    icon: peopleicon,
    title: "Über uns",
    link: "about",
    description: "Über make Utopia",
    content: <Suspense fallback="<div>Loading...</div>"><About /></Suspense>
},
{
    id: 3,
    icon: linksicon,
    title: "Links & Referenzen",
    link: "links",
    description: "Links und Referenzen",
    content: <Suspense fallback="<div>Loading...</div>"><Links /></Suspense>
},
{
    id: 4,
    icon: infoicon,
    title: "Impressum",
    link: "impressum",
    description: "Impressum",
    content: <Suspense fallback="<div>Loading...</div>"><Impressum /></Suspense>
},
{
    id: 6,
    parentId: 4,
    icon: lawicon,
    title: "Datenschutz",
    link: "datenschutz",
    description: "Datenschutzerklärung",
    content: <Suspense fallback="<div>Loading...</div>"><Datenschutz /></Suspense>
},
{
    id: 7,
    parentId: 2,
    title: "Team",
    link: "about-team",
    description: "Team",
    content: <Suspense fallback="<div>Loading...</div>"><Crew /></Suspense>
},
{
    id: 10,
    parentId: 2,
    autostart: false,
    icon: urkundeicon,
    title: "Kollektiv-Vertrag",
    link: "kollektiv-vertrag",
    description: "Kollektiv-Vertrag",
    content: <Suspense fallback="<div>Loading...</div>"><Kollektivvertrag /></Suspense>
},
{
    id: 8,
    parentId: 0,
    title: "Cloud",
    link: "products-nextcloud",
    description: "Nextcloud",
    content: <Suspense fallback="<div>Loading...</div>"><Nextcloud /></Suspense>
},
{
    id: 9,
    parentId: 0,
    title: "Videokonferenz",
    link: "products-bigbluebutton",
    description: "BigBlueButton",
    content: <Suspense fallback="<div>Loading...</div>"><BBB /></Suspense>
},
{
    id: 11,
    parentId: 0,
    title: "ERP/CRM",
    link: "products-erp",
    description: "ERP",
    content: <Suspense fallback="<div>Loading...</div>"><ERP /></Suspense>
},
{
    id: 12,
    parentId: 0,
    title: "Schulsoftware",
    link: "products-moodle",
    description: "Moodle und Linuxmuster",
    content: <Suspense fallback="<div>Loading...</div>"><Moodle /></Suspense>
},
{
    id: 13,
    parentId: 0,
    title: "Webshop",
    link: "products-woocommerce",
    description: "WooCommerce",
    content: <Suspense fallback="<div>Loading...</div>"><WooCommerce /></Suspense>
},

];

