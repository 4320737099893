import React from 'react';
import { Link, Route, Routes } from 'react-router-dom';
import {apps} from '../content/apps';
import ContentComp from '../content/content';
import Start from '../start';
import {Layout, Menu} from 'antd';
import {SettingOutlined, CodeOutlined, DesktopOutlined, LeftCircleOutlined, InfoCircleOutlined} from '@ant-design/icons';
import './overview.css';

const {
    Header, Footer, Sider, Content,
  } = Layout;
  
export default class Overview extends React.Component {    
    // constructor(props) {
    //     if (props.location.pathname === props.match.path || props.location.pathname === props.match.path+'/') {
    //         props.history.push(props.match.path+'/products')
    //         props.location.pathname = props.match.path+'/products'
    //     }
    //     super(props);
    // }

    render() {
      let defaultSelectedKey = '';
      //const location = this.props.location.pathname.split('/') 
      const app = apps.filter(app => 'link' in app) // && location[2] === app.link)
      let dok = ['pages']
      if (app.length === 1) {
          defaultSelectedKey = app[0].link
          if ('parentId' in app) {
            const papp = apps.filter(papp => papp.id === app.parentId)
            dok.push('S'+papp[0].link)
          } else {
              dok.push('S'+app[0].link)
          }
      }
      //console.log(dok, defaultSelectedKey);
      let menuItems = [
            {
                label: <span><InfoCircleOutlined /><span>Seiten</span></span>,
                key: "pages",
                children: apps.filter(app => ('link' in app && !('parentId' in app))).map(app => {                          
                          const children = apps.filter(sapp => ('parentId' in sapp && sapp.parentId === app.id))
                          if (children.length === 0) {
                              return {
                                key: app.link,
                                label: <Link to={'/overview/'+app.link}>{app.title}</Link>
                              } 
                          } else {
                              return {
                                key: 'S'+app.link,
                                label: <span>{app.title}</span>,
                                children: [
                                    {
                                        key: app.link,
                                        label: <Link to={'/overview/'+app.link}>Übersicht</Link>
                                    }
                                ].concat(children.map(capp => {
                                      return {
                                        key: capp.link,
                                        label: <Link to={'/overview/'+capp.link}>{capp.title}</Link>
                                      }}))
                              } 
                          }

                      })
            },
            {  
                key: 'site',
                label: <span><SettingOutlined /><span>Ansicht</span></span>,
                children: [
                    {key: 'Shell', label: <Link to="/shell"><CodeOutlined /><span>Shell</span></Link>},
                    {key: 'Window', label: <Link to="/start"><DesktopOutlined /><span>GUI</span></Link>},
                    {key: 'Back', label: <Link to="/"><LeftCircleOutlined /><span>Zurück</span></Link>},
                ]
            }
            
      ]
      return (
        <div className="OverView">
              <Layout hasSider>
                  <Sider collapsible theme='light' breakpoint="md" width="250">  
                      <div className="logo">
                          <Link to="/"><img src="/images/logo-kollektiv.png" alt=">make Utopia_ - IT Kollektiv Hamburg"/></Link>
                      </div>
                      <Menu mode="inline" defaultOpenKeys={dok} defaultSelectedKeys={[defaultSelectedKey]} items={menuItems} /> 
                  </Sider>
                  <Layout hasSider={false}>
                      <Header>
                      </Header>
                      <Content>
                        <Routes>
                          <Route path="/" element={<Start />}/>
                          <Route path=":contentName" element={<ContentComp />}/>
                        </Routes>
                      </Content>
                      <Footer>
                      make Utopia GmbH - IT Kollektiv Hamburg, Große Elbstraße 58, 22767 Hamburg
                      </Footer>
                  </Layout>
              </Layout>
        </div>
      );
    }
  }
