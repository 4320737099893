import React from 'react';
import {apps} from './apps';
import { useParams } from "react-router-dom";

function withParams(Component) {
  return props => <Component {...props} params={useParams()} />;
}

class Content extends React.Component {    
    render() {
      let c = apps.find(app => this.props.params.contentName === app.link);
      return (
        <div className='content'>
              {c ? c.content : <div><h1>Fehler 404</h1><p>Die Seite die du versuchst aufzurufen gibt es nicht...</p></div>}
        </div>
      );
    }
  }

export default withParams(Content);