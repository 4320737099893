import React from 'react';
import { Link } from 'react-router-dom'
import { Row, Col } from 'antd' 
import {Helmet} from "react-helmet";

export default class Start extends React.Component {    
    render() {
        const style = { width: '100%' }
      
        return (
            <div className="StartScreen">
            <Helmet>
                <title>make Utopia - IT-Kollektiv Hamburg</title>
                <meta name="description" content="IT und Software Angebote, Beratung und Entwicklung für Kollektive, Unternehmen, Vereine und Organisationen" />
                <meta name="keywords" content="IT-Kollektiv, Hamburg" />
                <meta property="og:type" content="article" />
                <meta property="og:title" content="make Utopia - IT Kollektiv Hamburg" />
                <meta property="og:description" content="IT und Software Angebote, Beratung und Entwicklung für Kollektive, Unternehmen,Vereine und Organisationen" />
            </Helmet>
            <h1>make Utopia - IT Kollektiv Hamburg</h1>
            <p>Software Entwicklung, Beratung und Support.</p>
            <Row style={{marginBottom: 10}} className="selectMedium">
            <Col xs={{ span: 6, offset: 1 }} lg={{ span: 6, offset: 1 }}><Link to="/shell"><img src="./images/mu-disk514.png" alt="start shell" style={style} /></Link></Col>
            <Col xs={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }}><Link to="/start"><img src="./images/mu-disk.png" alt="start window manager" style={style} /></Link></Col>
            <Col xs={{ span: 6, offset: 2 }} lg={{ span: 6, offset: 2 }}><Link to="/overview/products"><img src="./images/mu-stick.png" alt="start site" style={style} /></Link></Col>
            </Row>
            <p>Wir haben unsere Seite in drei Varianten für Sie aufbereitet.
            Bitte klicken Sie auf ein Speichermedium, um die Seite von make 
            Utopia - IT Kollektiv Hamburg in der entsprechenden Variante zu
            sehen (von links nach rechts):<br /> 
            Shell, Desktop GUI, aktuelle Variante (normale Webseite).<br />
            Diese Seite verwendet keine Cookies, daher gibt es auch kein
            Hinweis Banner.</p>
            <p>
            <Link to="/overview/impressum">Impressum</Link>
            </p>
            <footer>Copyright (C) since 2019 by make Utopia GmbH - IT Kollektiv Hamburg</footer>
        </div>
      );
    }
  }
